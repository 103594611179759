import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CertificationStatus, CertificationStatusEnum } from '../../utils/enum';
import { getIconByCertificationStatus } from '../../utils/products/utils';
import PortalWrapper from '../PortalWrapper';

interface CertificationStatusDropdownFilterProps {
  selectedCertificationStatusFilter: CertificationStatus | null;
  handleCertificationStatusChange: (status: CertificationStatus | null) => void;
  isDisabled?: boolean;
}

const CertificationStatusDropdownFilter: React.FC<CertificationStatusDropdownFilterProps> = ({
  selectedCertificationStatusFilter,
  handleCertificationStatusChange,
  isDisabled
}) => {
  const handleReset = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleCertificationStatusChange(null);
  };

  const title = selectedCertificationStatusFilter !== null
    ? CertificationStatusEnum[selectedCertificationStatusFilter]
    : 'Zertifizierung Status';

  return (
    <Dropdown>
      <Dropdown.Toggle
        disabled={isDisabled}
        variant="outline-primary"
        id="dropdown-certification-status"
        className="custom-dropdown-focus me-2"
      >
        {selectedCertificationStatusFilter !== null && (
          <FontAwesomeIcon icon={faTimes} className="me-2 cursor-pointer" onClick={handleReset} />
        )}
        {title}
      </Dropdown.Toggle>
      <PortalWrapper>
        <Dropdown.Menu>
          {Object.entries(CertificationStatusEnum).map(([key, value]) => (
            <Dropdown.Item
              key={key}
              eventKey={key}
              onClick={() => handleCertificationStatusChange(Number(key) as CertificationStatus)}
            >
              {getIconByCertificationStatus(Number(key) as CertificationStatus)}
              <span>{value}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </PortalWrapper>
    </Dropdown>
  );
};

export default CertificationStatusDropdownFilter;
