import { Permissions } from "../interfaces";
import { moduleTranslations } from "./enum";

export const formatEuro = (value: number | string) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(Number(value));
};

export const formatNumber = (value: string) => {
  return parseFloat(value).toFixed(2).toString();
};

export const formatGermanDate = (dateStr: string) => {
  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    return null;
  }

  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };
  const formattedDate = new Intl.DateTimeFormat('de-DE', optionsDate).format(
    date
  );

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  const formattedTime = new Intl.DateTimeFormat('de-DE', optionsTime).format(
    date
  );

  return `${formattedDate} - ${formattedTime} Uhr`;
};

export const formatDateWithoutTime = (dateStr: string) => {
  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    return dateStr;
  }
  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const formattedDate = new Intl.DateTimeFormat('de-DE', optionsDate).format(
    date
  );

  return formattedDate;
};

export function formatDate(dateString: string, format:string ) {
  const date = new Date(dateString);

  const padZero = (number: number) => (number < 10 ? '0' : '') + number;

  const replacements: any = {
      Y: date.getFullYear(),
      m: padZero(date.getMonth() + 1),
      d: padZero(date.getDate()),
      H: padZero(date.getHours()),
      i: padZero(date.getMinutes()),
      s: padZero(date.getSeconds())
  };

  return format.replace(/Y|m|d|H|i|s/g, match => replacements[match]);
}


export function formatIban(iban: string): string {
  const parts = iban.split(' ');

  if (parts.length <= 2) {
    return iban;
  }

  const firstPart = parts[0];
  const lastPart = parts[parts.length - 1];

  const middleParts = parts.slice(1, parts.length - 1);
  let maskedMiddleParts = middleParts
    .map((part) => 'X'.repeat(part.length))
    .join(' ');

  return `${firstPart} ${maskedMiddleParts} ${lastPart}`;
}

export function normalizeString(str: string) {
  const div = document.createElement('div');
  div.innerHTML = str;
  return div?.textContent!.trim();
}

export function normalizeJsonString(jsonString: string): string {
  const parsedObj = JSON.parse(jsonString);
  const sortedObj = sortObjectKeys(parsedObj);
  return JSON.stringify(sortedObj);
}

function sortObjectKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj)
      .sort()
      .reduce((sortedObj, key) => {
        sortedObj[key] = sortObjectKeys(obj[key]);
        return sortedObj;
      }, {} as { [key: string]: any });
  } else {
    return obj;
  }
}

export const generateRandomHex = () => {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join(
    ''
  );
};

export function getModuleList(input: any): string {
  if (input === '*') return '*';

  if (!input) {
    return "-"
  }

  try {
    const parsedInput = JSON.parse(input);
    const translatedKeys = Object.keys(parsedInput).map((key) => {
      return moduleTranslations[key as keyof Permissions] || key;
    });
    return translatedKeys.join(', ');
  } catch (error) {
    console.error('Invalid input:', error);
    return '';
  }
}

export function getModuleArray(input: any): { key: string, rights: string[] }[] | string {
  if (!input) return '';

  if (input === '*') return '*';
  try {
    const parsedInput = JSON.parse(input ?? '');
    const keys = Object.keys(parsedInput).map(key => ({
      key,
      rights: Object.entries(parsedInput[key]).filter(([, value]) => value).map(([right]) => right)
    }));
    return keys;
  } catch (error) {
    console.error('Invalid input:', error);
    return [];
  }
}

export function formatApiKey(str: string) {
  if (!str) {
    return ''
  }
  if (str.length <= 10) {
    return str;
  }
  return str.slice(0, 10) + '**********';
}

export const sanitizeInput = (input: string): string => {
  return input.toLowerCase().replace(/[^a-z0-9]/g, '_');
};