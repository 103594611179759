import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
  InputGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Task } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { useParams } from 'react-router-dom';
import { SelectOption } from '../../form/SelectWithSearch';
import { PermissionsEnum } from '../../../utils/enum';
import useCompanyNavigate from '../../../utils/hooks/useCompanyNavigate';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import DeleteModal from '../../DeleteModal';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import SelectGroup from '../../form/SelectGroup';
import FormGroup from '../../form/FormGroup';

/* Options for cron expression fields (minutes, hours, etc.) */
const minutesOptions: SelectOption[] = [
  { value: '*', label: 'Jede Minute' },
  { value: '*/5', label: 'Alle 5 Minuten' },
  { value: '*/10', label: 'Alle 10 Minuten' },
  { value: '*/15', label: 'Alle 15 Minuten' },
  { value: '*/30', label: 'Alle 30 Minuten' },
  { value: '*/45', label: 'Alle 45 Minuten' },
  ...Array.from({ length: 60 }, (_, i) => ({
    value: i.toString().padStart(2, '0'),
    label: i.toString().padStart(2, '0')
  }))
];

const hoursOptions: SelectOption[] = [
  { value: '*', label: 'Jede Stunde' },
  { value: '*/2', label: 'Alle 2 Stunden' },
  { value: '*/4', label: 'Alle 4 Stunden' },
  { value: '*/8', label: 'Alle 8 Stunden' },
  { value: '*/12', label: 'Alle 12 Stunden' },
  ...Array.from({ length: 24 }, (_, i) => ({
    value: i.toString().padStart(2, '0'),
    label: `${i.toString().padStart(2, '0')}:00`
  }))
];

const daysOptions: SelectOption[] = [
  { value: '*', label: 'Jeden Tag' },
  ...Array.from({ length: 31 }, (_, i) => ({
    value: (i + 1).toString(),
    label: `${i + 1}.`
  }))
];

const monthsOptions: SelectOption[] = [
  { value: '*', label: 'Jeden Monat' },
  { value: '1', label: 'Januar' },
  { value: '2', label: 'Februar' },
  { value: '3', label: 'März' },
  { value: '4', label: 'April' },
  { value: '5', label: 'Mai' },
  { value: '6', label: 'Juni' },
  { value: '7', label: 'Juli' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'Oktober' },
  { value: '11', label: 'November' },
  { value: '12', label: 'Dezember' }
];

const weekdaysOptions: SelectOption[] = [
  { value: '*', label: 'N/A' },
  { value: '0', label: 'Sonntag' },
  { value: '1', label: 'Montag' },
  { value: '2', label: 'Dienstag' },
  { value: '3', label: 'Mittwoch' },
  { value: '4', label: 'Donnerstag' },
  { value: '5', label: 'Freitag' },
  { value: '6', label: 'Samstag' }
];

type AddEditTaskModalProps = {
  modalTitle: string;
  task?: Task;
  onSubmitSuccess: (task: number) => void;
  onModalClose: () => void;
};

interface FormValues {
  className: string;
  isActive: 0 | 1;
  dependencies: string[];
  crontab: string;
  runOnlyOnce: 0 | 1;
  minutes: string;
  hours: string;
  days: string;
  months: string;
  weekdays: string;
  description: string;
}

const AddEditTaskModal: React.FC<AddEditTaskModalProps> = ({
  modalTitle,
  task,
  onSubmitSuccess,
  onModalClose,
}) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const { taskId } = useParams<{ taskId?: string }>();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const { userHasPermissionByRight } = usePermissions();
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const companyNavigate = useCompanyNavigate();
  const [availableTasks, setAvailableTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<string>('');
  const [nameError, setNameError] = useState<string | null>(null);

  /* Initialize form values, populate with task data if provided */
  const [formValues, setFormValues] = useState<FormValues>({
    className: task?.className ?? '',
    description: task?.description ?? '',
    isActive: task?.isActive ?? 0,
    dependencies: task?.dependencies ?? [],
    crontab: task?.crontab ?? '',
    runOnlyOnce: task?.runOnlyOnce ?? 0,
    minutes: task?.crontab?.split(' ')[0] ?? '*',
    hours: task?.crontab?.split(' ')[1] ?? '*',
    days: task?.crontab?.split(' ')[2] ?? '*',
    months: task?.crontab?.split(' ')[3] ?? '*',
    weekdays: task?.crontab?.split(' ')[4] ?? '*',
  });

  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  /* Fetch available tasks when component mounts */
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await ApiClient.get('/tasks');
        setAvailableTasks(response.data.list);
      } catch (error) {
        showToast('Fehler beim Laden der CronTasks', true);
      }
    };

    fetchTasks();
  }, []);

  /* Compare current form values with initial values to detect changes */
  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  /* Handle form submission; create or update task based on presence of taskId */
  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        const crontab = `${formValues.minutes} ${formValues.hours} ${formValues.days} ${formValues.months} ${formValues.weekdays}`;

        const taskData = {
          description: formValues.description,
          runOnlyOnce: formValues.runOnlyOnce,
          isActive: formValues.isActive,
          className: formValues.className,
          crontab
        };

        let response;
        if (taskId) {
          response = await ApiClient.put(`/tasks/${taskId}`, taskData);
        } else {
          response = await ApiClient.post('/tasks', taskData);
        }
        const id = response.data.tasks_id;
        onSubmitSuccess(id);
        setFormValues(response.data);
        setInitialFormValues(response.data);
        showToast('Erfolgreich gespeichert', false);
      } catch (error) {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        onModalClose();
        setValidated(false);
      }
    }
  };

  /* Handle changes to form input fields, including validation for 'name' */
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;

    if (id === 'name') {
      const regex = /^[A-Za-z][A-Za-z0-9]*$/;

      if (value === '' || regex.test(value)) {
        setFormValues((prev) => ({ ...prev, [id]: value }));
        setNameError(null);
      } else {
        setNameError('Zeichen nicht erlaubt');
      }
    } else {
      setFormValues((prev) => ({ ...prev, [id]: value }));
    }
  };

  /* Handle toggle changes for boolean fields like 'isActive' and 'runOnlyOnce' */
  const handleToggleChange = (
    e: ChangeEvent<HTMLInputElement>,
    id: keyof FormValues
  ) => {
    setFormValues((prev) => ({
      ...prev,
      [id]: e.target.checked ? 1 : 0,
    }));
  };

  /* Add a selected task as a dependency to the current task */
  const addDependency = () => {
    const taskToAdd = availableTasks.find(task => task.id === parseInt(selectedTask));
    if (taskToAdd && !formValues.dependencies.includes(taskToAdd.className)) {
      setFormValues(prev => ({
        ...prev,
        dependencies: [...prev.dependencies, taskToAdd.className]
      }));
      setSelectedTask('');
    }
  };

  /* Remove a task dependency from the current task */
  const removeDependency = (depName: string) => {
    setFormValues(prev => ({
      ...prev,
      dependencies: prev.dependencies.filter(dep => dep !== depName)
    }));
  };

  /* Render dropdown for selecting task dependencies */
  const renderDependencyDropdown = () => (
    <Form.Group className="mb-3">
      <Form.Label className='text-black'>Abhängigkeiten</Form.Label>
      <InputGroup>
        <Form.Select
          value={selectedTask}
          onChange={(e) => setSelectedTask(e.target.value)}
          aria-label="Select Task Dependency"
          className="bg-grey text-black"
        >
          <option value="">Wählen Sie eine Aufgabe</option>
          {availableTasks
            .filter((task) => {
              const isCurrentTask = task.id === parseInt(taskId || '');
              const isAlreadySelected = formValues.dependencies.includes(task.className);
              return !isCurrentTask && !isAlreadySelected;
            })
            .map((task) => (
              <option key={task.id} value={task.id}>
                {task.className}
              </option>
            ))}
        </Form.Select>
        <Button variant="primary" onClick={addDependency}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </InputGroup>
    </Form.Group>
  );

  /* Handle deletion of the task and display a confirmation modal and navigate to /tasks */
  const handleDeleteItem = (message: string, isError: boolean) => {
    showToast(message, isError);
    onModalClose();
    companyNavigate('/tasks');
  };

  return (
    <>
      <Modal
        centered
        show
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
        fullscreen
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button p-0"
              >
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                {task && <DeleteModal
                  modalTitle="Löschen?"
                  buttonName="Löschen"
                  entityIds={[task?.id!]}
                  entityType='tasks'
                  onSubmit={handleDeleteItem}
                  onDeleteModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewTasks, 'delete')}>
                </DeleteModal>}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <Row>
              <Col lg={6}>
                <FormGroup<FormValues>
                  id="className"
                  label="PHP-Klassenname"
                  type="text"
                  value={formValues['className']}
                  onChange={handleInputChange}
                  required={false}
                  nameError={nameError}
                />
                <FormGroup<FormValues>
                  id="description"
                  label="Beschreibung"
                  type="textarea"
                  value={formValues['description']}
                  onChange={handleInputChange}
                  required={true}
                />
              </Col>
              <Col lg={6}>
                {renderDependencyDropdown()}
                <Row>
                  <Col lg={4}>
                    {formValues.dependencies.length > 0 ? (
                      <ol className="list-unstyled text-black">
                        {formValues.dependencies.map((depName, index) => (
                          <li key={index} className="d-flex justify-content-between align-items-center mb-2">
                            <span className="me-3">{index + 1}. {depName}</span>
                            <Button
                              className="no-border"
                              variant="outline-danger"
                              size="sm"
                              onClick={() => removeDependency(depName)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </li>
                        ))}
                      </ol>
                    ) : (
                      <p className="text-black">Keine Abhängigkeiten</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='py-5 my-3'>
              <Col lg={2}>
                <SelectGroup<FormValues>
                  id="minutes"
                  label="Minuten:"
                  options={minutesOptions}
                  value={formValues['minutes']}
                  onChange={handleInputChange}
                  required={true}
                />
              </Col>
              <Col lg={2}>
                <SelectGroup<FormValues>
                  id="hours"
                  label="Stunden:"
                  options={hoursOptions}
                  value={formValues['hours']}
                  onChange={handleInputChange}
                  required={true}
                />
              </Col>
              <Col lg={2}>
                <SelectGroup<FormValues>
                  id="days"
                  label="Tage:"
                  options={daysOptions}
                  value={formValues['days']}
                  onChange={handleInputChange}
                  required={true}
                />
              </Col>
              <Col lg={2}>
                <SelectGroup<FormValues>
                  id="months"
                  label="Monate:"
                  options={monthsOptions}
                  value={formValues['months']}
                  onChange={handleInputChange}
                  required={true}
                />
              </Col>
              <Col lg={2}>
                <SelectGroup<FormValues>
                  id="weekdays"
                  label="Wochentage:"
                  options={weekdaysOptions}
                  value={''}
                  onChange={handleInputChange}
                  required={true}
                />
              </Col>
              <Col lg={2} className='d-flex align-items-center'>
                <h4>= {formValues.minutes} {formValues.hours} {formValues.days} {formValues.months} {formValues.weekdays}</h4>
              </Col>
            </Row>

            <Row>
              <Col className='d-flex'>
                <Form.Group className="mb-3 text-black me-3" controlId="isActive">
                  <Form.Label>Aktiv</Form.Label>
                  <Form.Check
                    type="switch"
                    id="isActive"
                    checked={!!formValues.isActive}
                    onChange={(e) => handleToggleChange(e, 'isActive')}
                  />
                </Form.Group>
                <Form.Group className="mb-3 text-black" controlId="runOnlyOnce">
                  <Form.Label>Nur einmal ausführen?</Form.Label>
                  <Form.Check
                    type="switch"
                    id="runOnlyOnce"
                    checked={!!formValues.runOnlyOnce}
                    onChange={(e) => handleToggleChange(e, 'runOnlyOnce')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              type="submit"
              className='d-none'
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditTaskModal;
