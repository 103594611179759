import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ProductType, ProductTypeEnum } from '../../utils/enum';
import { getIconByProductType } from '../../utils/products/utils';
import PortalWrapper from '../PortalWrapper';

interface ProductTypeDropdownFilterProps {
    selectedProductTypeFilter: keyof typeof ProductTypeEnum | null;
    handleProductTypeChange: (type: keyof typeof ProductTypeEnum | null) => void;
    isDisabled?: boolean;
}

const ProductTypeDropdownFilter: React.FC<ProductTypeDropdownFilterProps> = ({
    selectedProductTypeFilter,
    handleProductTypeChange,
    isDisabled
}) => {
    const handleReset = (event: React.MouseEvent) => {
        event.stopPropagation();
        handleProductTypeChange(null);
    };

    const title = selectedProductTypeFilter !== null
        ? ProductTypeEnum[selectedProductTypeFilter]
        : 'Produktart';

    return (
        <Dropdown>
            <Dropdown.Toggle
                disabled={isDisabled}
                variant="outline-primary"
                id="dropdown-product-type"
                className="custom-dropdown-focus me-2"
            >
                {selectedProductTypeFilter !== null && (
                    <FontAwesomeIcon icon={faTimes} className="me-2 cursor-pointer" onClick={handleReset} />
                )}
                {title}
            </Dropdown.Toggle>
            <PortalWrapper>
                <Dropdown.Menu>
                    {Object.entries(ProductTypeEnum).map(([key, value]) => (
                        <Dropdown.Item
                            key={key}
                            eventKey={key}
                            onClick={() => handleProductTypeChange(key as any)}
                        >
                            {getIconByProductType(Number(key) as ProductType)}
                            {value}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default ProductTypeDropdownFilter;
