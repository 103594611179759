import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { PublicationStatus, PublicationStatusEnum } from '../../utils/enum';
import { getIconByPublicationStatus } from '../../utils/products/utils';
import PortalWrapper from '../PortalWrapper';

interface PublicationStatusDropdownFilterProps {
    selectedPublicationStatusFilter: PublicationStatus | null;
    handlePublicationStatusChange: (status: PublicationStatus | null) => void;
    isDisabled?: boolean;
}

const PublicationStatusDropdownFilter: React.FC<PublicationStatusDropdownFilterProps> = ({
    selectedPublicationStatusFilter,
    handlePublicationStatusChange,
    isDisabled
}) => {
    const handleReset = (event: React.MouseEvent) => {
        event.stopPropagation();
        handlePublicationStatusChange(null);
    };

    const title = selectedPublicationStatusFilter !== null
        ? PublicationStatusEnum[selectedPublicationStatusFilter]
        : 'Veröffentlichung Status';

    return (
        <Dropdown>
            <Dropdown.Toggle
                disabled={isDisabled}
                variant="outline-primary"
                id="dropdown-publication-status"
                className="custom-dropdown-focus me-2"
            >
                {selectedPublicationStatusFilter !== null && (
                    <FontAwesomeIcon icon={faTimes} className="me-2 cursor-pointer" onClick={handleReset} />
                )}
                {title}
            </Dropdown.Toggle>
            <PortalWrapper>
                <Dropdown.Menu>
                    {Object.entries(PublicationStatusEnum).map(([key, value]) => (
                        <Dropdown.Item
                            key={key}
                            eventKey={key}
                            onClick={() => handlePublicationStatusChange(Number(key) as PublicationStatus)}
                        >
                            {getIconByPublicationStatus(Number(key) as PublicationStatus)}
                            {value}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default PublicationStatusDropdownFilter;
