import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getSettingIcon } from '../../utils/settings/utils';
import { SettingArea, SettingAreaEnum } from '../../utils/enum';

interface SettingAreaDropdownFilterProps {
    selectedSettingAreaFilter: SettingArea | null;
    handleSettingAreaChange: (area: SettingArea | null) => void;
}

const SettingAreaDropdownFilter: React.FC<SettingAreaDropdownFilterProps> = ({
    selectedSettingAreaFilter,
    handleSettingAreaChange,
}) => {
    const handleReset = (event: React.MouseEvent) => {
        event.stopPropagation();
        handleSettingAreaChange(null);
    };

    const title =
        selectedSettingAreaFilter !== null
            ? SettingAreaEnum[selectedSettingAreaFilter]
            : 'Bereich';

    return (
        <div className="d-flex align-items-center">
            <DropdownButton
                className="custom-dropdown-focus me-2"
                title={
                    <span>
                        {selectedSettingAreaFilter !== null && (
                            <FontAwesomeIcon icon={faTimes} className="me-2 cursor-pointer" onClick={handleReset} />
                        )}
                        {title}
                    </span>
                }
                variant="outline-primary"
                onSelect={(k) => handleSettingAreaChange(k as SettingArea)}
            >
                {Object.entries(SettingAreaEnum).map(([key, value]) => (
                    <Dropdown.Item key={key} eventKey={key}>
                        {getSettingIcon(key)}
                        {value}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </div>
    );
};

export default SettingAreaDropdownFilter;
