import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Partner } from '../../interfaces';
import PortalWrapper from '../PortalWrapper';

interface PartnerNameDropdownFilterProps {
    selectedPartnerNameFilter: keyof Partner | null;
    handlePartnerNameChange: (type: keyof Partner | null) => void;
    partner: Partner;
    isDisabled?: boolean;
}

const PartnerNameDropdownFilter: React.FC<PartnerNameDropdownFilterProps> = ({
    selectedPartnerNameFilter,
    handlePartnerNameChange,
    partner,
    isDisabled
}) => {
    const handleReset = (event: React.MouseEvent) => {
        event.stopPropagation();
        handlePartnerNameChange(null);
    };

    const title = selectedPartnerNameFilter !== null
        ? partner[selectedPartnerNameFilter]
        : 'Partner';

    return (
        <Dropdown>
            <Dropdown.Toggle
                disabled={isDisabled}
                variant="outline-primary"
                id="dropdown-partner-name"
                className="custom-dropdown-focus me-2"
            >
                {selectedPartnerNameFilter !== null && (
                    <FontAwesomeIcon icon={faTimes} className="me-2 cursor-pointer" onClick={handleReset} />
                )}
                {title}
            </Dropdown.Toggle>
            <PortalWrapper>
                <Dropdown.Menu>
                    {Object.entries(partner).map(([key, value]) => (
                        <Dropdown.Item
                            key={key}
                            eventKey={key}
                            onClick={() => handlePartnerNameChange(key as keyof Partner)}
                        >
                            {value}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default PartnerNameDropdownFilter;
