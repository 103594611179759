import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
    Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { FileNames, Setting } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import Card from '../../bootstrap/card';

type FileNamesModalProps = {
    modalTitle: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    contract: string
    image: string
}

const FileNamesModal: React.FC<FileNamesModalProps> = ({
    modalTitle,
    setting,
    onSubmitSuccess,
    onModalClose
}) => {
    const fileNames = setting?.details as FileNames

    const { show, message, error, showToast, hideToast } = useToast();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [formValues, setFormValues] = useState({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        contract: fileNames?.contract,
        image: fileNames?.image,
    });
    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = await ApiClient.put(
                    `/settings/${setting?.id}`,
                    prepareFormDataForSubmit(formValues)
                );
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const prepareFormDataForSubmit = (formValues: FormValues) => {
        return {
            title: formValues.title,
            ident: formValues.ident,
            description: formValues.description,
            details: JSON.stringify({
                contract: formValues.contract,
                image: formValues.image
            })
        };
    }

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { id, value } = e.target;
        setFormValues((prev: any) => ({ ...prev, [id]: value }));
    };

    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        disabled = false,
        marginBottom = 3
    ) => (
        <Form.Group className={`mb-${marginBottom} w-100`} controlId={id}>
            {label && <Form.Label className='text-black'>{label}</Form.Label>}

            {type === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    rows={5}
                    type={type}
                    value={formValues[id]}
                    onChange={handleInputChange}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            ) : (
                <Form.Control
                    type={type}
                    value={type !== 'file' ? formValues[id] : ''}
                    onChange={handleInputChange}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            )}

            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Form.Group>
    )

    return (
        <>
            <Modal
                centered
                show
                onHide={onModalClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                                style={{ padding: 0 }}
                            >
                                <span className="visually-hidden">More options</span>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>

                            <Row>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            <div className='mb-3'>
                                                {createFormGroup('contract', 'Benennungsrichtlinie für Vertragsdokumente', 'text', false, false, 1)}
                                                <small >
                                                    Bitte das Feld leer lassen, wenn keine Richtlinie angewendet werden soll!
                                                </small>
                                            </div>

                                            <div>
                                                {createFormGroup('image', 'Benennungsrichtlinie für Bilder', 'text', false, false, 1)}
                                                <small>
                                                    Bitte das Feld leer lassen, wenn keine Richtlinie angewendet werden soll!
                                                </small></div>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            <div><label className="form-label mb-1 text-black">Verfügbare Platzhalter</label></div>
                                            <small className='mb-2'>Bitte verwenden Sie auch die eckigen Klammern. Der Platzhalter wird durch den entsprechenden Wert ersetzt.</small>
                                            <Table borderless size="sm" className='m-0 p-0 text-black'>
                                                <tbody>
                                                    <tr>
                                                        <td className="p-0">[city]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">Stadtname</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-0">[date]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">Aktuelles Datum im Format YYYY-DD-MM</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-0">[sourceTitle]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">Ursprünglicher Titel der Datei</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-0">[street]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">Straßenname</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-0">[streetNr]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">Hausnummer</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-0">[zipcode]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">Postleitzahl</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-0">[companyShortcut]</td>
                                                        <td className="p-2"><FontAwesomeIcon icon={faCaretRight} /></td>
                                                        <td className="p-0">Firmenkürzel</td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default FileNamesModal;
