import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../../products/GenericInfosCard';
import { Location, PaymentMethods } from '../../../interfaces';
import { formatDateWithoutTime, formatEuro } from '../../../utils/utils';
import { YesNoMapping, getEnumValue } from '../../../utils/enum';

interface CostsDetailsProps {
  isLoading?: boolean;
  location?: Location;
  paymentMethods?: PaymentMethods
}

const CostsDetails: React.FC<CostsDetailsProps> = ({
  isLoading,
  location,
  paymentMethods
}) => {
  function getCostsInfo1() {
    const contractInfo1 = [
      {
        label: 'Miete monatlich, Netto',
        value: location?.costsMonthlyNet ? formatEuro(location?.costsMonthlyNet) : '-',
      },
      {
        label: 'Miete monatlich, Brutto',
        value: location?.costsMonthlyGross ? formatEuro(location?.costsMonthlyGross) : '-',
      },
      {
        label: 'Weitere Mieten',
        value: location?.costsFurtherRents ? `immer zum ${location?.costsFurtherRents}ten des Monats` : '-',
      },
      {
        label: 'Kosten bei Kündigung',
        value: location?.costsOfTermination ? formatEuro(location?.costsOfTermination) : '-',
      },
      {
        label: 'Einmalige Kosten',
        value: location?.costsOneTime ? formatEuro(location?.costsOneTime) : '-',
      },
      {
        label: 'Kaution',
        value: location?.costsDeposit ? formatEuro(location?.costsDeposit) : '-',
      },
    ];
    return contractInfo1;
  }

  function getCostsInfo2() {
    const contractInfo2 = [
      {
        label: 'Bezahlung erste Miete',
        value: location?.costsPaymentFirstRent ? formatDateWithoutTime(location?.costsPaymentFirstRent) : '-',
      },
      {
        label: 'Zahlart',
        value: getEnumValue(paymentMethods ?? {}, location?.costsPaymentMethod?.toString()),
      },
      {
        label: 'Mietsteigerung',
        value: location?.costsRentIncrease?.toString() || '-',
      },
      {
        label: 'Umsatzsteuer',
        value: location?.costsTax ? formatEuro(location?.costsTax) : '-',
      },
      {
        label: 'Umsatzsteuerpflichtig',
        value: getEnumValue(YesNoMapping, location?.costsTaxDuty.toString()),
      },
    ];
    return contractInfo2;
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          <GenericInfosCard
            title="Kosten"
            infos={getCostsInfo1()}
          ></GenericInfosCard>
        </Col>
        <Col lg={6}>
          <GenericInfosCard
            title={''}
            infos={getCostsInfo2()}
          ></GenericInfosCard>
        </Col>
      </Row>
    </>
  );
};

export default CostsDetails;
