import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

type AddEditOptimizationModalProps = {
  modalType: 'edit' | 'new';
  buttonName?: string;
  optimisationId?: string;
  type: 'topic' | 'area' | 'focus';
  onSubmitSuccess: () => void;
};

interface FormValues {
  id: string;
  keywords: string;
  title: string;
  topic: string;
}

interface SelectOption {
  value: string;
  label: string;
}

const AddEditOptimizationModal: React.FC<AddEditOptimizationModalProps> = ({
  type,
  modalType,
  buttonName,
  optimisationId,
  onSubmitSuccess,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formValues, setFormValues] = useState({
    id: '',
    title: '',
    keywords: '',
    topic: '',
  });
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  useEffect(() => {
    if (optimisationId) {
      //fetch data of optimisation
    }
  }, [optimisationId]);

  const checkIfDataChanged = (): boolean => {
    const keys = Object.keys(formValues) as Array<keyof FormValues>;
    return keys.some((key) => {
      const currentValue = formValues[key];
      const initialValue = initialFormValues[key];
      return currentValue !== initialValue;
    });
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    console.log(formValues);
    onSubmitSuccess();
    handleClose();

    // check type here and submit accordingly

    // const method = existingData && Object.keys(existingData).length > 0 ? 'PUT' : 'POST';

    // try {
    //   const response = await fetch(endpoint, {
    //     method: 'PUT',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify(formValues),
    //   });

    //   if (response.ok) {
    //     handleClose(); // Close modal on success
    //     // Further actions here: refresh data, display a success message, etc.
    //   } else {
    //     alert('Failed to submit the form. Please try again.');
    //   }
    // } catch (error) {
    //   console.error('Submission error:', error);
    //   alert('An error occurred. Please check your connection and try again.');
    // }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormValues((prev) => ({ ...prev, [id]: value }));
  };

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text'
  ) => (
    <Form.Group className="mb-3 w-100" controlId={id}>
      {label && <Form.Label>{label}</Form.Label>}

      {type === 'textarea' ? (
        <Form.Control
          as="textarea"
          rows={5}
          type={type}
          value={formValues[id]}
          onChange={handleInputChange}
          required
        />
      ) : (
        <Form.Control
          type={type}
          value={type !== 'file' ? formValues[id] : ''}
          onChange={handleInputChange}
          required
        />
      )}

      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>
  );

  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: SelectOption[],
    placeholder?: string
  ) => (
    <Form.Group className="mb-3 w-100" controlId={id}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select value={formValues[id]} onChange={handleInputChange} required>
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  return (
    <>
      {modalType === 'edit' ? (
        <Link to="" className="btn-link" onClick={handleShow}>
          {buttonName}
        </Link>
      ) : (
        <Button
          variant="secondary"
          className="btn-soft-primary round-button"
          style={{ padding: 0, width: '25px', height: '25px' }}
          onClick={handleShow}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      )}

      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {type === 'topic'
                ? `Thema ${
                    modalType === 'edit' ? 'bearbeiten' : 'hinzufügen'
                  } ${optimisationId || ''}`
                : type === 'area'
                ? `Bereich ${
                    modalType === 'edit' ? 'bearbeiten' : 'hinzufügen'
                  } ${optimisationId || ''}`
                : `Schwerpunkt ${
                    modalType === 'edit' ? 'bearbeiten' : 'hinzufügen'
                  } ${optimisationId || ''}`}
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            {modalType === 'edit' && (
              <Dropdown className="me-2">
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  className="btn-soft-primary dropdown-no-arrow round-button"
                  style={{ padding: 0 }}
                >
                  <span className="visually-hidden">More options</span>
                  <FontAwesomeIcon icon={faEllipsis} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleClose}>Schließen</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            <Button variant="danger me-2" onClick={handleClose}>
              Verwerfen
            </Button>
            <Button
              className=""
              disabled={!checkIfDataChanged()}
              variant="primary"
              onClick={submit}
            >
              Speichern
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={submit}>
            <Row>
              <Col lg={8}>
                {type !== 'topic' && (
                  <Row>
                    <Col lg={3}>
                      {type === 'area'
                        ? 'Thema auswählen'
                        : 'Unterthema auswählen'}
                    </Col>
                    <Col lg={8}>
                      {' '}
                      {createSelectGroup(
                        'topic',
                        '',
                        [
                          { value: 'topic', label: 'Topic 1' },
                          { value: 'topic2', label: 'Topic 2' },
                        ],
                        'Auswählen...'
                      )}
                    </Col>
                    <Col
                      className="d-flex justify-content-center align-items-center"
                      lg={1}
                    >
                      <div className="mb-3">
                        <AddEditOptimizationModal
                          modalType="new"
                          onSubmitSuccess={() => {}}
                          type={type === 'area' ? 'topic' : 'area'}
                        ></AddEditOptimizationModal>
                      </div>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col lg={3}>Titel</Col>
                  <Col lg={8}>{createFormGroup('title', '')}</Col>
                  <Col lg={1}></Col>
                </Row>
                <Row>
                  <Col lg={3}>Keywords</Col>
                  <Col lg={8}>
                    {createFormGroup('keywords', '', 'textarea')}
                  </Col>
                  <Col lg={1}></Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddEditOptimizationModal;
