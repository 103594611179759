import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines } from '@fortawesome/pro-regular-svg-icons';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PortalWrapper from './PortalWrapper';
import { FieldConfigs } from '../interfaces';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';

interface ColumnSelectionProps {
  selectedColumns: string[];
  onSelectionChange: (selected: string[]) => void;
  fieldConfigs: FieldConfigs;
}

interface SortableItemProps {
  id: string;
  label: string;
  handleCheckboxChange: (value: string) => void;
  isChecked: boolean;
  isDisabled: boolean;
  isFirstItem: boolean;
}

// Renders a sortable item with drag-and-drop functionality and a checkbox for selecting/deselecting columns.
const SortableItem: React.FC<SortableItemProps> = ({ id, label, handleCheckboxChange, isChecked, isDisabled, isFirstItem }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString({
      ...transform,
      x: 0,
      scaleX: 1,
      scaleY: 1,
    } as any),
    transition,
    zIndex: isDragging ? 1000 : 'auto',
  };

  return (
    <Dropdown.Item
      ref={setNodeRef}
      style={isFirstItem ? undefined : style}
      {...(isFirstItem ? {} : attributes)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d-flex align-items-center w-100 cursor-pointer">
        <div className="flex-grow-1" onClick={() => !isFirstItem && handleCheckboxChange(id)}>
          <Form.Check
            type="checkbox"
            label={<span className="word-break">{label}</span>}
            checked={isChecked}
            onChange={() => handleCheckboxChange(id)}
            onClick={(e) => e.stopPropagation()}
            disabled={isDisabled || isFirstItem}
            className="checkbox-pointer m-0"
          />
        </div>
        {!isFirstItem && (
          <div {...listeners} className="cursor-grab">
            <FontAwesomeIcon icon={faGripLines} />
          </div>
        )}
      </div>
    </Dropdown.Item>
  );
};

// Manages the selection and ordering of columns with drag-and-drop and checkboxes.
const ColumnSelection: React.FC<ColumnSelectionProps> = ({ selectedColumns, onSelectionChange, fieldConfigs }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(selectedColumns);
  const [show, setShow] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  // Synchronizes the selectedItems state with the selectedColumns prop when it changes.
  useEffect(() => {
    setSelectedItems(selectedColumns);
  }, [selectedColumns]);

  // Checks if there are changes between the selectedItems and selectedColumns to track unsaved changes.
  useEffect(() => {
    const hasChanges = JSON.stringify(selectedItems) !== JSON.stringify(selectedColumns);
    setIsChanged(hasChanges);
  }, [selectedItems, selectedColumns]);

  // Handles the selection/deselection of columns via checkboxes.
  const handleCheckboxChange = (value: string) => {
    setSelectedItems((prevSelectedItems) => {
      let newSelectedItems;
      if (prevSelectedItems.includes(value)) {
        if (prevSelectedItems.length === 1) {
          return prevSelectedItems; // Prevents deselecting the last column.
        }
        newSelectedItems = prevSelectedItems.filter((item) => item !== value);
      } else {
        newSelectedItems = [...prevSelectedItems, value];
      }
      return newSelectedItems;
    });
  };

  // Applies the changes by confirming the column selection and closing the dropdown.
  const handleConfirmClick = () => {
    onSelectionChange(selectedItems);
    setIsChanged(false);
    setShow(false);
  };

  // Toggles the dropdown menu open or closed.
  const handleToggle = async (isOpen: boolean) => {
    setShow(isOpen);
  };

  // Handles the reordering of selected columns when a drag-and-drop event finishes.
  const handleDragEnd = ({ active, over }: any) => {
    if (!over || active.id === over.id) {
      return;
    }

    setSelectedItems((prevSelectedItems) => {
      const oldIndex = prevSelectedItems.indexOf(active.id);
      const newIndex = prevSelectedItems.indexOf(over.id);

      if (oldIndex === 0 || newIndex === 0) {
        return prevSelectedItems;
      }

      const newSelectedItems = arrayMove(prevSelectedItems, oldIndex, newIndex);
      return newSelectedItems;
    });
  };

  return (
    <Dropdown show={show} onToggle={handleToggle}>
      <Dropdown.Toggle as="div" className="no-caret cursor-pointer d-inline-block">
        <div className='px-2' onClick={() => setShow(!show)}>
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </div>
      </Dropdown.Toggle>

      <PortalWrapper>
        <Dropdown.Menu className="custom-scrollbar py-0 custom-width-250">
          <Dropdown.ItemText className="sticky-item-text py-2">
            <p className="mb-0 custom-font-size-08 font-weight-normal">TABELLENANSICHT BEARBEITEN</p>
          </Dropdown.ItemText>

          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={selectedItems}>
              {selectedItems.map((item) => (
                <SortableItem
                  key={item}
                  id={item}
                  label={fieldConfigs[item]?.fieldLabel || item}
                  handleCheckboxChange={handleCheckboxChange}
                  isChecked={selectedItems.includes(item)}
                  isDisabled={selectedItems.length === 1 && selectedItems.includes(item)}
                  isFirstItem={item === selectedItems[0]}
                />
              ))}
            </SortableContext>
          </DndContext>

          <div className="horizontal-line my-3"></div>

          {Object.keys(fieldConfigs)
            .filter((item) => !selectedItems.includes(item))
            .map((item) => (
              <Dropdown.Item className="cursor-pointer" key={item} as="div" onClick={(e) => e.stopPropagation()}>
                <div className="d-flex align-items-center w-100 cursor-pointer">
                  <div className="flex-grow-1 cursor-pointer" onClick={() => handleCheckboxChange(item)}>
                    <Form.Check
                      type="checkbox"
                      label={<span className="cursor-pointer word-break">{fieldConfigs[item].fieldLabel}</span>}
                      className="checkbox-pointer m-0"
                      checked={selectedItems.includes(item)}
                      onChange={() => handleCheckboxChange(item)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </div>
              </Dropdown.Item>
            ))}

          <div className="d-flex justify-content-end sticky-bottom">
            <Button size="sm" className="btn btn-soft-primary m-2 mt-3" onClick={handleConfirmClick} disabled={!isChanged}>
              Anwenden
            </Button>
          </div>
        </Dropdown.Menu>
      </PortalWrapper>
    </Dropdown>
  );
};

export default ColumnSelection;
