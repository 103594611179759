import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
  InputGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { ApiKey } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { useParams } from 'react-router-dom';
import { SelectOption } from '../../form/SelectWithSearch';
import { ApiKeyStatus, ApiKeyStatusEnum, PermissionsEnum, getEnumValue, moduleTranslations, } from '../../../utils/enum';
import useCompanyNavigate from '../../../utils/hooks/useCompanyNavigate';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import DeleteModal from '../../DeleteModal';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { Permissions } from '../../../interfaces';
import { generateRandomHex } from '../../../utils/utils';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import Card from '../../bootstrap/card';

type AddEditApiKeyModalProps = {
  modalTitle: string;
  apiKey?: ApiKey;
  onSubmitSuccess: (apiKey: number) => void;
  onModalClose: () => void;
};

interface FormValues {
  title: string;
  status: ApiKeyStatus
  description: string
  modules: string
  expiration: string;
  origins: string;
}

const AddEditApiKeyModal: React.FC<AddEditApiKeyModalProps> = ({
  modalTitle,
  apiKey,
  onSubmitSuccess,
  onModalClose
}) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const { apikeyId } = useParams<{ apikeyId?: string }>();
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const { userHasPermissionByRight } = usePermissions();
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const companyNavigate = useCompanyNavigate()
  const [formValues, setFormValues] = useState({
    title: apiKey?.title ?? generateRandomHex(),
    status: apiKey?.status ?? 0,
    description: apiKey?.description ?? '',
    modules: apiKey?.modules ? JSON.parse(apiKey?.modules) : [],
    expiration: apiKey?.expiration ?? '',
    origins: apiKey?.origins ?? '',
    name: apiKey?.name ?? '',
  });

  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        let response;

        const updatedFormValues = {
          ...formValues,
          modules: JSON.stringify(formValues.modules),
        };

        if (apikeyId) {
          response = await ApiClient.put(`/apikeys/${apikeyId}`, updatedFormValues);
        } else {
          response = await ApiClient.post('/apikeys', updatedFormValues);
        }
        const id = response.data.id;
        onSubmitSuccess(id);
        setFormValues(response.data);
        setInitialFormValues(response.data);
        showToast('Erfolgreich gespeichert', false);
      } catch (error) {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        onModalClose();
        setValidated(false);
      }
    }
  };


  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormValues((prev: any) => ({ ...prev, [id]: value }));
  };

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false,
    infoLabel: string = ''
  ) => {

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const formatDate = (dateStr: string = "") => {
      if (type === 'date') {
        return dateStr.split(' ')[0];
      }
      return dateStr;
    };

    return (
      <Form.Group className="mb-3 w-100 text-black" controlId={id.toString()}>
        <Form.Label className='text-black'>{label} {infoLabel && <small>{infoLabel}</small>}</Form.Label>
        {type !== 'password' ? (
          <Form.Control
            style={{ backgroundColor: '#F9F9F9' }}
            className='text-black'
            type={type}
            value={type !== 'file' ? (type === 'date' ? formatDate(formValues[id]?.toString()) : formValues[id]) : ''}
            onChange={handleInputChange}
            required={required}
            isInvalid={validated && !formValues[id]}
          />
        ) : (
          <InputGroup>
            <Form.Control
              style={{ backgroundColor: '#F9F9F9' }}
              className='text-black'
              type={showPassword ? 'text' : 'password'}
              value={formValues[id] || ''}
              onChange={handleInputChange}
              required={required}
              isInvalid={validated && !formValues[id]}
              autoComplete="new-password"
            />
            <Button
              variant=""
              onClick={toggleShowPassword}
              className="border-left-0"
              style={{ backgroundColor: '#F9F9F9', border: '1px solid #eee', }}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </Button>
          </InputGroup>
        )}
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
    );
  };

  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: SelectOption[],
    placeholder?: string,
    required = false
  ) => (
    <Form.Group
      style={{ color: 'black' }}
      className="mb-3 w-100"
      controlId={id}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        value={formValues[id]}
        onChange={handleInputChange}
        required={required}
        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setFormValues((prev) => {
      let updatedModules = [...prev.modules];
      if (checked) {
        if (!updatedModules.includes(id)) {
          updatedModules.push(id);
        }
      } else {
        updatedModules = updatedModules.filter(moduleId => moduleId !== id);
      }
      return { ...prev, modules: updatedModules };
    });
  };

  const renderCheckboxes = (label: string) => {
    const permissions: Array<keyof Permissions> = [
      'locations',
      'products',
      'settings',
    ];
    return (
      <Form.Group style={{ color: 'black' }} className="mb-3 w-100">
        {label && <Form.Label>{label}</Form.Label>}
        {permissions.map((permission) => (
          <Form.Check
            key={permission}
            type="checkbox"
            id={permission}
            label={getEnumValue(moduleTranslations, permission)}
            checked={formValues.modules.includes(permission)}
            onChange={handleCheckboxChange}
          />
        ))}
      </Form.Group>
    );
  };

  const handleDeleteItem = (message: string, isError: boolean) => {
    showToast(message, isError)
    onModalClose()
    companyNavigate('/apikeys')
  }


  return (
    <>
      <Modal
        centered
        show
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button"
                style={{ padding: 0 }}
              >
                <span className="visually-hidden">More options</span>
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                {apiKey && <DeleteModal
                  modalTitle="Löschen?"
                  buttonName="Löschen"
                  entityIds={[apiKey?.id!]}
                  entityType='apikeys'
                  onSubmit={handleDeleteItem}
                  onDeleteModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'delete')}>
                </DeleteModal>}

              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <>
              <Row>
                <Col lg={6}>
                  <Card className='card-block card-stretch card-height'>
                    <Card.Body>
                      {createFormGroup('title', 'Titel/API-Schlüssel', 'password', true)}
                      {createFormGroup('description', 'Beschreibung', 'text',)}
                      {createSelectGroup(
                        'status',
                        'Status',
                        Object.entries(ApiKeyStatusEnum).map(([value, label]) => ({
                          value,
                          label,
                        })),
                        'Auswählen...',
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className='card-block card-stretch card-height'>
                    <Card.Body>
                      {createFormGroup('origins', 'Origin-Header(s)', 'text',)}
                      {createFormGroup('expiration', 'Ablaufdatum', 'date',)}
                      {renderCheckboxes('Module')}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditApiKeyModal;
