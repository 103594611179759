import {
  faChevronDown,
  faChevronUp,
  faCircleSmall,
  faPlus,
  faSquareSmall,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCircleSmall as circleSmall,
  faGripVertical,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import Nestable, { RenderItem } from 'react-nestable';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { Col, Row } from 'react-bootstrap';

const generateUniqueId = (() => {
  let counter = 0;
  return () => `unique_id_${counter++}`;
})();

const transformItems = (items: Item[]) => {
  return items.map((item: any) => {
    const newItem = {
      ...item,
      id: generateUniqueId(),
      text: item.id,
    };
    if (item.children) {
      newItem.children = transformItems(item.children);
    }
    return newItem;
  });
};

const revertTransformItems = (items: Item[]): Item[] => {
  return items
    .map((item) => {
      const originalItem: Item = {
        id: item.text ?? '',
        ue: item.ue,
      };
      if (item.children && item.children.length > 0) {
        const transformedChildren = revertTransformItems(item.children);
        if (transformedChildren.length > 0) {
          originalItem.children = transformedChildren;
        }
      }

      return originalItem;
    })
    .filter(
      (item) => item.id !== '' || (item.children && item.children.length > 0)
    );
};

interface Item {
  id: string;
  ue?: string | number;
  text?: string;
  children?: Item[];
}

interface NestableComponentProps {
  educationContent: string;
  onTotalUesChange: (total: number) => void;
  onItemsChange: (items: string) => void;
  cols?: number
}

const NestableComponent: React.FC<NestableComponentProps> = ({
  educationContent,
  onTotalUesChange,
  onItemsChange,
  cols = 10
}) => {
  const initialData = JSON.parse(educationContent);
  const transformedData = transformItems(initialData ? initialData : []);
  const [collapsedItems, setCollapsedItems] = useState(new Set<string>());
  const nestableRef = useRef<Nestable>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedId, setSelectedId] = useState('');
  const [items, setItems] = useState<Item[]>([
    ...transformedData,
    { id: generateUniqueId(), text: '', ue: '' },
  ]);

  useEffect(() => {
    if (nestableRef.current) {
      nestableRef.current.setState({
        collapsedItems: Array.from(collapsedItems),
      });
    }
  }, [collapsedItems]);

  useEffect(() => {
    const calculateTotalUe = (items: Item[]) => {
      let totalUe = 0;

      const calculateItemUe = (item: Item): number => {
        if (item.ue?.toString() && item.ue.toString().trim() !== '') {
          return Number(item.ue);
        }
        if (item.children && item.children.length > 0) {
          return item.children.reduce(
            (acc, child) => acc + calculateItemUe(child),
            0
          );
        }
        return 0;
      };
      items.forEach((item) => {
        totalUe += calculateItemUe(item);
      });

      return totalUe;
    };

    const total = calculateTotalUe(items);

    const originalItems = revertTransformItems(items);
    onItemsChange(JSON.stringify(originalItems));
    onTotalUesChange(total);
  }, [items, onTotalUesChange]);

  const toggleCollapse = (id: string, depth?: number) => {
    setCollapsedItems((prev) => {
      const newCollapsedItems = new Set(prev);
      if (depth === 0 && newCollapsedItems.has(id)) {
        return new Set<string>();
      } else if (newCollapsedItems.has(id)) {
        selectedId === id && newCollapsedItems.delete(id);
      } else {
        newCollapsedItems.add(id);
      }

      return newCollapsedItems;
    });
  };

  const handleDeleteItem = (idToDelete: string): void => {
    const removeItemById = (items: Item[], idToDelete: string): Item[] => {
      const filteredItems = items.filter((item) => item.id !== idToDelete);
      const itemsWithDeletedChildren = filteredItems.map((item) => {
        if (item.children && item.children.length > 0) {
          return {
            ...item,
            children: removeItemById(item.children, idToDelete),
          };
        }
        return item;
      });

      return itemsWithDeletedChildren;
    };

    setItems((prevItems) => removeItemById(prevItems, idToDelete));
  };

  const handleUpdateItem = (
    idToUpdate: string,
    updatedItemContent: Partial<Item>
  ): void => {
    const updateItemById = (
      items: Item[],
      idToUpdate: string,
      updatedContent: Partial<Item>
    ): Item[] => {
      return items.map((item) => {
        if (item.id === idToUpdate) {
          return { ...item, ...updatedContent };
        } else if (item.children) {
          return {
            ...item,
            children: updateItemById(item.children, idToUpdate, updatedContent),
          };
        }
        return item;
      });
    };

    setItems((prevItems) =>
      updateItemById(prevItems, idToUpdate, updatedItemContent)
    );
  };

  const handleDirectAddNewItem = (e: any) => {
    if (!selectedId) return;
    const newItem: Item = { id: generateUniqueId(), ue: '', text: '' };
    const addNewItem = (
      items: Item[],
      newItem: Item,
      parentId: string
    ): Item[] =>
      items.map((item) => {
        if (item.id === parentId) {
          const updatedChildren = item.children
            ? [...item.children, newItem]
            : [newItem];
          return { ...item, children: updatedChildren };
        } else if (item.children) {
          return {
            ...item,
            children: addNewItem(item.children, newItem, parentId),
          };
        }
        return item;
      });

    setItems((prev) => addNewItem(prev, newItem, selectedId));

    setCollapsedItems((prev) => {
      const newCollapsedItems = new Set(prev);
      newCollapsedItems.add(selectedId);
      return newCollapsedItems;
    });
  };

  const renderItem = (props: {
    item: Item;
    index: number;
    depth: number;
    isDraggable: boolean;
    collapseIcon: React.ReactNode;
    handler: React.ReactNode;
  }) => {
    const { item, collapseIcon, handler, depth } = props;
    const isSelected = selectedId === item.id;

    return (
      <div className={`item-content level-${depth}`}>
        <div
          className="d-flex justify-content-between p-2 me-2"
          style={{
            backgroundColor: `${isSelected ? '#F9F9F9' : ''}`,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedId(item.id);
            toggleCollapse(item.id, depth);
          }}
        >
          <div style={{ cursor: 'pointer' }}>
            {isSelected && (
              <span onClick={(e) => e.stopPropagation()}>
                {depth !== 2 && (
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="me-3"
                    onClick={handleDirectAddNewItem}
                  />
                )}
                <span className="me-3"> {handler}</span>
              </span>
            )}

            <span className="me-2">
              {' '}
              {depth === 0 && (
                <FontAwesomeIcon icon={faCircleSmall} size="xs" />
              )}
              {depth === 1 && <FontAwesomeIcon icon={circleSmall} size="xs" />}
              {depth === 2 && (
                <FontAwesomeIcon icon={faSquareSmall} size="xs" />
              )}
            </span>

            <span>
              {' '}
              <input
                value={item.text || ''}
                onChange={(e) => {
                  handleUpdateItem(item.id, { ...item, text: e.target.value });
                  setSelectedId(item.id);
                }}
                placeholder={
                  depth === 0
                    ? 'Neues Modul...'
                    : depth === 1
                    ? `Neue Lerneinheit...`
                    : 'Neues Lernkapitel'
                }
                style={{
                  width: '400px',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: `${isSelected ? '#F9F9F9' : ''}`,
                }}
              ></input>
            </span>
          </div>

          <div
            onClick={(e) => e.stopPropagation()}
            className="d-flex align-items-center"
          >
            {depth !== 0 && (
              <span className="me-3">
                <input
                  value={item.ue || ''}
                  placeholder="0"
                  onChange={(e) => {
                    handleUpdateItem(item.id, { ...item, ue: e.target.value });
                  }}
                  onClick={() => setSelectedId(item.id)}
                  style={{
                    backgroundColor: `${isSelected ? '#FFF' : ''}`,
                    width: '40px',
                    border: 'none',
                  }}
                />
                <span className="ms-2">UE</span>
              </span>
            )}

            <FontAwesomeIcon
              className="me-3"
              icon={faTrashCan}
              onClick={() => handleDeleteItem(item.id)}
              style={{
                visibility: isSelected ? 'visible' : 'hidden',
              }}
            />
            <div
              style={{
                minHeight: '13px',
                minWidth: '13px',
                visibility: isSelected ? 'visible' : 'hidden',
              }}
            >
              {collapseIcon}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Row>
      <Col></Col>
      <Col lg={cols}>
        <div ref={wrapperRef}>
          <Nestable
            maxDepth={3}
            items={items}
            renderItem={renderItem as RenderItem}
            collapsed={true}
            renderCollapseIcon={({ isCollapsed }) =>
              isCollapsed ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )
            }
            onChange={(e) => setItems(e.items as any)}
            onCollapseChange={(e: any) => {
              e?.openIds &&
                collapsedItems.size < e?.openIds.length &&
                setCollapsedItems((prev) => {
                  const newCollapsedItems = new Set(prev);
                  e.openIds.forEach((id: string) => newCollapsedItems.add(id));
                  return newCollapsedItems;
                });
            }}
            handler={<FontAwesomeIcon icon={faGripVertical} className="me-2" />}
            ref={nestableRef}
          />
        </div>
      </Col>
      <Col></Col>
    </Row>
  );
};

export default NestableComponent;
