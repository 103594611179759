import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { ApiKeyStatus, ApiKeyStatusEnum } from '../utils/enum';
import { getApiKeyStatusIcon, getApiKeysStatusColor } from '../utils/apikeys/utils';
import PortalWrapper from './PortalWrapper';


interface ApiKeyStatusDropdownProps {
  statusApiKey: ApiKeyStatus;
  handleApiKeyStatusUpdate: (status: ApiKeyStatus) => void;
  isDisabled?: boolean
}

const ApiKeyStatusDropdown: React.FC<ApiKeyStatusDropdownProps> = ({ statusApiKey, handleApiKeyStatusUpdate, isDisabled }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        disabled={isDisabled}
        variant="link"
        id="dropdown-apikey-basic"
        className={`${getApiKeysStatusColor(statusApiKey)} ps-0`}
      >
        {getApiKeyStatusIcon(statusApiKey)}
        {ApiKeyStatusEnum[statusApiKey]}
      </Dropdown.Toggle>
      <PortalWrapper>
        <Dropdown.Menu>
          {Object.entries(ApiKeyStatusEnum)
            .filter(([statusKey, _]) => Number(statusKey) !== statusApiKey)
            .map(([statusKey, statusValue]) => (
              <Dropdown.Item
                key={statusKey}
                onClick={() =>
                  handleApiKeyStatusUpdate(
                    Number(statusKey) as ApiKeyStatus
                  )
                }
              >
                {getApiKeyStatusIcon(Number(statusKey) as ApiKeyStatus)}
                <span className={`${getApiKeysStatusColor(Number(statusKey) as ApiKeyStatus)}`}>{statusValue}</span>
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </PortalWrapper>
    </Dropdown>
  );
};

export default ApiKeyStatusDropdown;
