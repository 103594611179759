import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TaskStatus } from "../enum";
import { faLock, faUnlock } from "@fortawesome/pro-solid-svg-icons";


export function getTaskStatusIcon(
    status: TaskStatus
) {
    let iconComponent;
    switch (Number(status)) {
        case 0:
            iconComponent = (
                <FontAwesomeIcon
                    className="me-2"
                    style={{ color: 'var(--bs-danger)' }}
                    icon={faLock}
                />
            );
            break;
        case 1:
            iconComponent = (
                <FontAwesomeIcon
                    className="me-2"
                    style={{ color: 'var(--bs-success)' }}
                    icon={faUnlock}
                />
            );
            break;
        default:
            break
    }
    return iconComponent;
}