const defaultRenderHeader = (
  columnKey: string,
  fieldConfigs: { [key: string]: any },
  requestSort: (key: string) => void,
  getSortCaret: (key: string, entityType: string) => JSX.Element | null,
  entityType: string
) => {
  let sortField = `${entityType}.${columnKey}`
  // Adjust the sortField if an optionprovider exists
  if (fieldConfigs[columnKey]?.optionsprovider?.includes('search')) {
    sortField = `${columnKey}.title`;
  }

  return (
    <th
      key={sortField}
      className="cursor-pointer position-relative table-cell-wrap"
      scope="col"
      onClick={() => requestSort(sortField)}
      title={fieldConfigs[columnKey]?.fieldLabel || columnKey}
    >
      {fieldConfigs[columnKey]?.fieldLabel || columnKey}
      <div className="position-absolute" style={{ right: 0, top: '8px' }}>
        {getSortCaret(columnKey, entityType)}
      </div>
    </th>
  );
};

export { defaultRenderHeader };