import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
  SettingTypeMap
} from '../../utils/enum';

import { ApiClient } from '../../services/ApiClient';
import {  Setting } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import GlobalDataCompaniesDetails from '../../components/settings/GlobalDataCompanies';
import GlobalDataProductsDetails from '../../components/settings/GlobalDataProducts';
import GlobalDataLocationsDetails from '../../components/settings/GlobalDataLocations';
import WebflowSync from '../../components/settings/WebflowSync';
import FileNamesDetails from '../../components/settings/FileNames';
import MeinNowAccounts from '../../components/settings/MeinNowAccounts';
import PartnerNamesDetails from '../../components/settings/PartnerNames';
import PaymentMethods from '../../components/settings/PaymentMethods';
import CounterIdents from '../../components/settings/CounterIdents';
import DefaultValuesExcelCertificationDetails from '../../components/settings/DefaultValuesExcelCertification';

function SettingDetail() {
  const { settingId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [setting, setSetting] = useState<Setting | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewSettings, 'read');

      if (hasPermission) {
        settingId && fetchSetting(settingId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [settingId, permissionsLoaded]);

  const fetchSetting = async (settingId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/settings/${settingId}`);
      const settingResponse = response.data;
      setSetting({...settingResponse, details: JSON.parse(settingResponse.details ? settingResponse.details : "{}")});
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setSetting(null)
    settingId && fetchSetting(settingId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              {settingId === SettingTypeMap.globalProductFields && <GlobalDataProductsDetails  setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></GlobalDataProductsDetails>}
              {settingId === SettingTypeMap.globalLocationFields && <GlobalDataLocationsDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></GlobalDataLocationsDetails>}
              {settingId === SettingTypeMap.globalCompanyData && <GlobalDataCompaniesDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></GlobalDataCompaniesDetails>}
              {settingId === SettingTypeMap.webflowSync && <WebflowSync setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></WebflowSync>}
              {settingId === SettingTypeMap.meinNowAccounts && <MeinNowAccounts setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></MeinNowAccounts>}
              {settingId === SettingTypeMap.fileNameTemplates && <FileNamesDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></FileNamesDetails>}
              {settingId === SettingTypeMap.locationsPartners && <PartnerNamesDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></PartnerNamesDetails>}
              {settingId === SettingTypeMap.paymentMethods && <PaymentMethods setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></PaymentMethods>}
              {settingId === SettingTypeMap.counterIdents && <CounterIdents setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></CounterIdents>}
              {settingId === SettingTypeMap.defaultValuesExcelCertification && <DefaultValuesExcelCertificationDetails setting={setting} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></DefaultValuesExcelCertificationDetails>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default SettingDetail;
